import { useEffect } from "react";

export const useThoughtmetric = (initId?: string, email?: string): void => {
  useEffect(() => {
    console.log("useThoughtmetric invoked with initId:", initId);
    if (!initId) {
      console.log("InitId is null or undefined, skipping script injection.");
      return;
    }

    // Ensure `thoughtmetricQueue` is defined on the window object
    if (!(window as any).thoughtmetricQueue) {
      console.log("Defining thoughtmetricQueue on window.");
      (window as any).thoughtmetricQueue = [];
    }

    const scriptExists = document.querySelector('script[src="https://pixel.thoughtmetric.io/tmpixel.min.js"]');
    if (!scriptExists) {
      console.log("Injecting Thoughtmetric script...");
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://pixel.thoughtmetric.io/tmpixel.min.js";

      script.onload = () => {
        console.log("Script loaded successfully.");
        try {
          (window as any).thoughtmetric = (window as any).thoughtmetric || function () {
            ((window as any).thoughtmetricQueue = (window as any).thoughtmetricQueue || []).push(arguments);
          };

          console.log("Initializing Thoughtmetric with ID:", initId);
          (window as any).thoughtmetric("init", initId);
          (window as any).thoughtmetric("pageview");

          if (email) {
            console.log("Sending lead event with email:", email);
            (window as any).thoughtmetric("event", "lead", { lead_id: email });
          }
        } catch (error) {
          console.error("Error initializing Thoughtmetric:", error);
        }
      };

      script.onerror = () => {
        console.error("Failed to load Thoughtmetric script. Ad blocker might be blocking it.");
      };

      document.head.appendChild(script);
    } else {
      console.log("Script already exists. Checking for Thoughtmetric function.");
      try {
        if (typeof (window as any).thoughtmetric === "function") {
          if (email) {
            console.log("Sending lead event with email:", email);
            (window as any).thoughtmetric("event", "lead", { lead_id: email });
          }
        } else {
          console.warn("Thoughtmetric function is not available. Ad blocker might be blocking it.");
        }
      } catch (error) {
        console.error("Error using Thoughtmetric:", error);
      }
    }
  }, [initId, email]);
};
